import type { EHR } from 'vim-os-js-browser/types';

const patientIncurances = new Set(['cloverhealth', 'clover health', 'clover']);

function checkInsurance(shouldCheckInsurancePartialMatching: boolean, insurance: string) {
  return shouldCheckInsurancePartialMatching
    ? insurance.toLocaleLowerCase().includes('clover')
    : patientIncurances.has(insurance.toLocaleLowerCase());
}

/**
 * Check if the patient has a valid insurance. Insurance in patientIncurances should be in lowercase.
 */
export function patientInsuranceValidation(
  VIM_SHOULD_CHECK_INSURANCE: boolean,
  patient: EHR.Patient,
  shouldCheckInsurancePartialMatching: boolean,
) {
  const { ehrInsurance } = patient?.insurance || {};
  if (!ehrInsurance) {
    return false;
  }

  return VIM_SHOULD_CHECK_INSURANCE
    ? checkInsurance(shouldCheckInsurancePartialMatching, ehrInsurance)
    : !checkInsurance(shouldCheckInsurancePartialMatching, ehrInsurance);
}
